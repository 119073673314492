import axios from "axios"
import store from "@/store"

const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

instance.defaults.timeout = 60000
instance.defaults.withCredentials = true
instance.defaults.xsrfHeaderName = 'X-CSRFToken'
instance.defaults.xsrfCookieName = 'csrftoken'

instance.interceptors.response.use(response => response,
    async ({ message, response, config }) => {
        if(response?.status === 503) {
            const originalRequest = config
            originalRequest._retry = true
            await new Promise((resolve) => setTimeout(resolve, 200))
            return instance(originalRequest)
        } else {
            if (response?.status === 401) {
                await store.dispatch('user/localUserLogout')
                location.reload()
            }
            throw response?.data
        }
    })

instance.interceptors.request.use(
    (config) => {
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

export default instance
